export const LOGIN = "LOGIN";

export const LOGOUT = "LOGOUT";

export const LANG = "LANG";

export const SELECT_NOW_TAB = "SELECT_NOW_TAB";

export const ROLE = "ROLE";

export const USERINFO = "USERINFO";

export const DARKTHEME = "DARKTHEME";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";

export const UPDATE_SELECTED_ORGANIZATION = "UPDATE_SELECTED_ORGANIZATION";
